<template>
  <div class="about">

      <!--顶部-->
      <headbox></headbox>

      <!-- 滑块组件 -->
      <banner positionSid="7184"></banner>

      <!--关于我们-->
      <gywm></gywm>

      <!--公司理念-->
      <gsln></gsln>

      <!-- 侧边导航 -->
      <sidebar ref="mysiderbar"></sidebar>

      <!--底部-->
      <bottom></bottom>

  </div>
</template>

<script>
import Swiper from 'swiper'
import headbox from "@/components/headfloat";//
import foot from "@/components/foot";
import bottom from "@/components/bottom";
import sidebar from "@/components/sidebar";
import banner from "@/components/banner";
import gsln from "@/components/gsln.vue";
import gywm from "@/components/gywm.vue";

export default {
    components: {
      gsln,
      gywm,
      banner,
      headbox,
      foot,
      bottom,
      sidebar,
    },
    data() {
        return {
        }
        },
    methods:
        {
            hideChat(e){
                this.$refs.mysiderbar.hideChat()
            },
        },

  mounted(){
    var swiper = new Swiper('.swiper-container', {
        direction: 'vertical',
        // slidesPerView: 1,
        // spaceBetween: 30,
        mousewheel: true,
        mousewheelControl : true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });
      // 页面增加全局点击
      window.addEventListener('click', this.hideChat)
  },
    beforeDestroy() {
        /* 组件销毁的时候要移除侦听器 */
        window.removeEventListener('click', this.hideChat)
    },
    beforeMount() {
    },
}
</script>

<style scoped>
    .about{
        width: 100%;
        overflow-x: hidden;
    }
</style>
